import { connect } from 'react-redux';

import PlaylistEdit from './PlaylistEdit';
import {
  goToHome,
  goToGenres,
  requestPlaylistEditContent,
  pauseSong,
  setPlaylistEditData,
  setPlaylistEditName,
  deleteSongFromPlaylistEdit,
  sortPlaylistEditSongs,
  requestEditPlaylist
} from '../../actions';

const mapStateToProps = (state: any) => ({
  loading: state.editPlaylist.loading,
  editing: state.editPlaylist.editing,
  songs: state.editPlaylist.songs,
  id: state.editPlaylist.id,
  name: state.editPlaylist.name,
});

const mapDispatchToProps = {
  requestPlaylistEditContent,
  goBack: goToHome,
  goToGenres,
  pauseSong,
  setPlaylistEditData,
  setPlaylistEditName,
  deleteSongFromPlaylistEdit,
  sortPlaylistEditSongs,
  requestEditPlaylist,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlaylistEdit);
