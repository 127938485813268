import { mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import axios from '../utils/axios';
import ActionTypes from '../constants/ActionTypes';
import { successGenreDetails, failureGenreDetails } from '../actions';

export const fetchGenreDetails = (action$: any) => action$.pipe(
  ofType(ActionTypes.REQUEST_GENRE_DETAILS),
  mergeMap((action: any) => {
    const genreId = action.payload.genreId;
    const sortBy = action.payload.columnName;
    const orderBy = action.payload.orderAsc ? 'asc' : 'desc';
    return axios.get(`/genres/${genreId}/songs?sort_by=${sortBy}&order_by=${orderBy}`)
      .then((response) => successGenreDetails(response.data))
      .catch((error) => failureGenreDetails(error));
  }),
);
