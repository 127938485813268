import { connect } from 'react-redux';

import AudioPreview from './AudioPreview';
import {
  startSong,
  pauseSong
} from '../../actions';

const mapStateToProps = (state: any) => ({
  id: state.audioPreview.id,
  path: state.audioPreview.path,
  loading: state.audioPreview.loading,
  playing: state.audioPreview.playing,
});

const mapDispatchToProps = {
  startSong,
  pauseSong,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AudioPreview);
