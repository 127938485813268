import { Action } from 'typescript-fsa';

import ActionTypes from '../constants/ActionTypes';
import { convertServerSongs } from '../utils/converters';
import { ISong } from './genreDetails';
import { IClient } from './playlists';

export interface IPlaylistDetails {
  id: number;
  name: string;
  createdAt: string;
  client: IClient;
  duration: number;
  loading: boolean;
  songs: ISong[];
}

const initialState: IPlaylistDetails = {
  id: 0,
  name: '',
  createdAt: '',
  client: {
    id: 0,
    name: '',
  },
  duration: 0,
  loading: false,
  songs: [],
};

export const playlistDetails = (state = initialState, action: Action<any>) => {
  switch (action.type) {
    case ActionTypes.REQUEST_PLAYLIST_DETAILS: {
      return {
        ...initialState,
        loading: true,
      };
    }
    case ActionTypes.SUCCESS_PLAYLIST_DETAILS: {
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
        createdAt: action.payload.createdAt,
        loading: false,
        songs: convertServerSongs(action.payload.content),
      };
    }
    case ActionTypes.FAILURE_PLAYLIST_DETAILS: {
      return initialState;
    }
    default:
      return state;
  }
};
