import { combineEpics } from 'redux-observable';

import * as RouteEpics from './routes';
import * as CreatePlaylistEpics from './createPlaylist';
import * as EditPlaylistEpics from './editPlaylist';
import * as PlaylistEpics from './playlist';
import { fetchPlaylistDetails } from './playlistDetails';
import { fetchPlaylists } from './playlists';
import { fetchGenres } from './genres';
import { fetchGenreDetails } from './genreDetails';
import { fetchUsers } from './users';

export default combineEpics(
  ...Object.values(RouteEpics),
  ...Object.values(CreatePlaylistEpics),
  ...Object.values(EditPlaylistEpics),
  ...Object.values(PlaylistEpics),
  fetchPlaylistDetails,
  fetchPlaylists,
  fetchGenres,
  fetchGenreDetails,
  fetchUsers,
);
