import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { isEqual } from 'lodash';

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import Header from '../../components/Header';
import GridHeader from '../../components/GridHeader';
import Song, { ISong } from '../../components/Song';
import { IHeaderActionsProps } from '../../components/Header/HeaderActions';
import genreDetailsHeaders from './headers';
import AudioPreview from '../../components/AudioPreview';
import Loading from '../../components/Loading';

interface IMatchParams {
  id: string;
}

interface IGenresDetailsProps extends RouteComponentProps<IMatchParams> {
  loading: boolean;
  songs: ISong[];
  columnOrder: any;
  isEditing: boolean;
  editingId: number;
  requestGenreDetails: (id: string) => void;
  addSongsToPlaylist: (songs: ISong[]) => void;
  addSongsToPlaylistEdit: (songs: ISong[]) => void;
  goToNewPlaylist: () => void;
  goToPlaylistEdit: (id: string) => void;
  goToGenres: () => void;
  sortSongsBy: (columnName: string) => void;
  pauseSong: () => void;
}

interface IGenreDetailsState {
  search: string;
  songs: ISong[];
}

class GenreDetails extends Component<IGenresDetailsProps, IGenreDetailsState> {
  constructor(props: IGenresDetailsProps) {
    super(props);
    this.state = {
      search: '',
      songs: this.props.songs,
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleAddSongsToPlaylist = this.handleAddSongsToPlaylist.bind(this);
  }

  componentDidMount() {
    this.props.requestGenreDetails({
      genreId: this.props.match.params.id,
      ...this.props.columnOrder,
    });
  }

  componentDidUpdate(prevProps: IGenresDetailsProps) {
    if (!isEqual(prevProps.columnOrder, this.props.columnOrder)) {
      this.props.requestGenreDetails({
        genreId: this.props.match.params.id,
        ...this.props.columnOrder,
      });
    }
  }

  componentWillUnmount() {
    this.props.pauseSong();
  }

  handleSearch(e: React.FormEvent<HTMLInputElement>): void {
    this.setState({
      search: e.currentTarget.value,
    });
  }

  handleSelect(id: number): void {
    const songs = this.props.songs.map((song: ISong) => {
      if (song.id === id) {
        song.selected = !song.selected;
      }
      return song;
    });
    this.setState({ songs });
  }

  handleAddSongsToPlaylist(): void {
    const songs: ISong[] = this.props.songs.filter(
      (song: ISong) => song.selected
    ).map((song: ISong) => {
      return { ...song, playing: false };
    });
    if (this.props.isEditing) {
      this.props.addSongsToPlaylistEdit(songs);
      this.props.goToPlaylistEdit(this.props.editingId.toString());
    } else {
      this.props.addSongsToPlaylist(songs);
      this.props.goToNewPlaylist();
    }
  }

  handleBack(): void {
    this.props.goToGenres();
  }

  render() {
    const selectedSongs = this.props.songs.filter((song: ISong) => song.selected === true);

    const actions: IHeaderActionsProps = {
      back: this.handleBack,
      addSongsToPlaylist: {
        disabled: selectedSongs.length === 0,
        submit: this.handleAddSongsToPlaylist,
      },
    };

    const songs = this.props.songs
      .filter(
        (song: ISong) =>
          song.name.toLowerCase().includes(this.state.search.toLowerCase()) ||
          song.artist.toLowerCase().includes(this.state.search.toLowerCase()) ||
          song.genre.name
            .toLowerCase()
            .includes(this.state.search.toLowerCase())
      )
      .map((song: ISong, index: number) => {
        song.selectFn = () => this.handleSelect(song.id);
        return (
          <Song
            key={index}
            id={song.id}
            name={song.name}
            path={song.path}
            artist={song.artist}
            genre={song.genre}
            duration={song.duration}
            selected={song.selected}
            selectFn={song.selectFn}
          />
        );
      });

    const content = (this.props.songs.length > 0)
      ? <SimpleBar style={{ height: '100%' }}>{songs}</SimpleBar>
      : <Loading />;
    return (
      <React.Fragment>
        <AudioPreview />
        <Header
          search={{ value: this.state.search, handleSearch: this.handleSearch }}
          actions={actions}
        />
        <section>
          <GridHeader sortBy={this.props.sortSongsBy} columns={genreDetailsHeaders} />
          {content}
        </section>
      </React.Fragment>
    );
  }
}

export default withRouter(GenreDetails);
