import React, { Component } from 'react';

import { numberToTime } from '../../utils/utils';

import Row, { ICellProps } from '../Row';
import { IAudioPreviewState } from '../../actions';

export interface ISongGenre {
  id: number;
  name: string;
}

export interface ISong {
  id: number;
  path: string;
  activeId: number;
  name: string;
  artist: string;
  genre: ISongGenre;
  duration: number;
  playing: boolean;
  loading: boolean;
  selected?: boolean;
  playSong: (song: IAudioPreviewState ) => void;
  pauseSong: () => void;
  deleteFn?: () => void;
  selectFn?: () => void;
}

class Song extends Component<ISong, {}>{
  constructor(props: ISong) {
    super(props);

    this.handleAudio = this.handleAudio.bind(this);
  }

  handleAudio() {
    const { playing, id, activeId, path, pauseSong, playSong } = this.props;
    if (playing && id === activeId) {
      pauseSong();
    } else {
      const data = {
        id,
        path,
      };
      playSong(data);
    }
  }

  render() {
    const {
      id,
      activeId,
      name,
      artist,
      genre,
      duration,
      playing,
      loading,
      deleteFn,
      selected,
      selectFn,
    } = this.props;

    let playClasses: string;
    if (playing && id === activeId) {
      playClasses = loading ? 'col-play playing loading' : 'col-play playing';
    } else {
      playClasses = 'col-play';
    }

    const content: ICellProps[] = [
      {
        type: 'action',
        content: (
          <div className={playClasses} onClick={this.handleAudio}>
            <div className='loading-row'>
              <div /><div /><div />
            </div>
          </div>
        ),
      },
      {
        type: 'text',
        content: name,
      },
      {
        type: 'text',
        content: artist,
      },
      {
        type: 'text',
        content: genre.name,
      },
      {
        type: 'text',
        content: numberToTime(duration),
      }
    ];

    if (deleteFn) {
      content.push({
        type: 'action',
        content: <div className='col-delete' onClick={deleteFn} />,
      });
    }

    if (selectFn) {
      const classes = selected ? 'col-select selected' : 'col-select';
      content.unshift({
        type: 'action',
        content: <div className={classes} onClick={selectFn} />,
      });
    }

    return <Row columns={content} classes='song' />;
  }
};

export default Song;
