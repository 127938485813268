import actionCreatorFactory from 'typescript-fsa';

import ActionTypes from '../constants/ActionTypes';
import { AxiosError } from 'axios';
import { ISong } from '../components/Song';

const actionCreator = actionCreatorFactory();

export interface IPlaylistServer {
  schedule_id: number;
  playlist_id: number;
  playlist_name: string;
  created_at: string;
  user_id: number;
  first_name: string;
  last_name: string;
  duration: number;
  family: number | null;
}

export interface IPlaylistDetailsServer {
  content: ISongServer[];
  created_at: string;
  created_by_id: number;
  duration: number;
  id: number;
  name: string;
  playlistgroups_id: number;
  sound: number;
  type: string;
  updated_at: string;
  updated_by_id: number;
  usergroups_id: number;
  version: number;
}

export interface IGenreServer {
  id: number;
  name: string;
  songs_count: number;
}

export interface ISongServer {
  id: number;
  name: string;
  artist: string;
  genre_id: number;
  genre_name: string;
  duration: number;
  path: string;
}

export interface IUserServer {
  id: number;
  first_name: string;
  last_name: string;
}

export interface ISortSong {
  songs: ISong[];
  oldIndex: number;
  newIndex: number;
}

export interface ICreatePlaylistServer {
  name: string;
  user_id: number;
  songs: number[];
}

export interface IDuplicatePlaylistServer {
  playlist_id: number;
  user_id: number;
}

export interface IAudioPreviewState {
  id: number;
  path: string;
}

export interface IEditPlaylistServer {
  id: number;
  name: string;
  songs: number[];
}

export interface IPlaylistEdit {
  id: number;
  name: string;
  user_id: number;
  songs: ISong[];
}

export const goToHome = actionCreator<undefined>(ActionTypes.GO_TO_HOME);
export const goToGenres = actionCreator<undefined>(ActionTypes.GO_TO_GENRES);
export const goToNewPlaylist = actionCreator<undefined>(ActionTypes.GO_TO_NEW_PLAYLIST);
export const goToGenreDetails = actionCreator<string>(ActionTypes.GO_TO_GENRE_DETAILS);
export const goToPlaylistDetails = actionCreator<string>(ActionTypes.GO_TO_PLAYLIST_DETAILS);
export const goToPlaylistEdit = actionCreator<string>(ActionTypes.GO_TO_PLAYLIST_EDIT);

export const requestPlaylists = actionCreator<any>(ActionTypes.REQUEST_PLAYLISTS);
export const successPlaylists = actionCreator<IPlaylistServer[]>(ActionTypes.SUCCESS_PLAYLISTS);
export const failurePlaylists = actionCreator<AxiosError>(ActionTypes.FAILURE_PLAYLISTS);

export const requestDeletePlaylist = actionCreator<number>(ActionTypes.REQUEST_DELETE_PLAYLIST);
export const successDeletePlaylist = actionCreator<number>(ActionTypes.SUCCESS_DELETE_PLAYLIST);
export const failureDeletePlaylist = actionCreator<AxiosError>(ActionTypes.FAILURE_DELETE_PLAYLIST);

export const requestDuplicatePlaylist = actionCreator<IDuplicatePlaylistServer>(ActionTypes.REQUEST_DUPLICATE_PLAYLIST);
export const successDuplicatePlaylist = actionCreator<IPlaylistServer>(ActionTypes.SUCCESS_DUPLICATE_PLAYLIST);
export const failureDuplicatePlaylist = actionCreator<AxiosError>(ActionTypes.FAILURE_DUPLICATE_PLAYLIST);

export const requestGenres = actionCreator<any>(ActionTypes.REQUEST_GENRES);
export const successGenres = actionCreator<IGenreServer[]>(ActionTypes.SUCCESS_GENRES);
export const failureGenres = actionCreator<AxiosError>(ActionTypes.FAILURE_GENRES);

export const requestGenreDetails = actionCreator<any>(ActionTypes.REQUEST_GENRE_DETAILS);
export const successGenreDetails = actionCreator<ISongServer[]>(ActionTypes.SUCCESS_GENRE_DETAILS);
export const failureGenreDetails = actionCreator<AxiosError>(ActionTypes.FAILURE_GENRE_DETAILS);

export const requestUsers = actionCreator<undefined>(ActionTypes.REQUEST_USERS);
export const successUsers = actionCreator<IUserServer[]>(ActionTypes.SUCCESS_USERS);
export const failureUsers = actionCreator<AxiosError>(ActionTypes.FAILURE_USERS);

export const setPlaylistUser = actionCreator<number>(ActionTypes.SET_PLAYLIST_USER);
export const setPlaylistName = actionCreator<string>(ActionTypes.SET_PLAYLIST_NAME);
export const addSongsToPlaylist = actionCreator<ISong[]>(ActionTypes.ADD_SONGS_TO_PLAYLIST);
export const deleteSongFromPlaylist = actionCreator<number>(ActionTypes.DELETE_SONG_FROM_PLAYLIST);
export const sortPlaylistSongs = actionCreator<ISortSong>(ActionTypes.SORT_PLAYLIST_SONGS);
export const resetPlaylist = actionCreator<undefined>(ActionTypes.RESET_PLAYLIST);

export const requestCreatePlaylist = actionCreator<ICreatePlaylistServer>(ActionTypes.REQUEST_CREATE_PLAYLIST);
export const successCreatePlaylist = actionCreator<undefined>(ActionTypes.SUCCESS_CREATE_PLAYLIST);
export const failureCreatePlaylist = actionCreator<AxiosError>(ActionTypes.FAILURE_CREATE_PLAYLIST);

export const requestPlaylistDetails = actionCreator<string>(ActionTypes.REQUEST_PLAYLIST_DETAILS);
export const successPlaylistDetails = actionCreator<IPlaylistDetailsServer>(ActionTypes.SUCCESS_PLAYLIST_DETAILS);
export const failurePlaylistDetails = actionCreator<AxiosError>(ActionTypes.FAILURE_PLAYLIST_DETAILS);

export const sortPlaylistsBy = actionCreator<any>(ActionTypes.SORT_PLAYLISTS_BY);
export const sortGenresBy = actionCreator<any>(ActionTypes.SORT_GENRES_BY);
export const sortSongsBy = actionCreator<any>(ActionTypes.SORT_SONGS_BY);

export const playSong = actionCreator<IAudioPreviewState>(ActionTypes.PLAY_SONG);
export const startSong = actionCreator<undefined>(ActionTypes.START_SONG);
export const pauseSong = actionCreator<undefined>(ActionTypes.PAUSE_SONG);

export const requestPlaylistEditContent = actionCreator<string>(ActionTypes.REQUEST_PLAYLIST_EDIT_CONTENT);
export const successPlaylistEditContent =
  actionCreator<IPlaylistDetailsServer>(ActionTypes.SUCCESS_PLAYLIST_EDIT_CONTENT);
export const failurePlaylistEditContent = actionCreator<AxiosError>(ActionTypes.FAILURE_PLAYLIST_EDIT_CONTENT);

export const setPlaylistEditData = actionCreator<IPlaylistEdit>(ActionTypes.SET_PLAYLIST_EDIT_DATA);
export const setPlaylistEditName = actionCreator<string>(ActionTypes.SET_PLAYLIST_EDIT_NAME);
export const addSongsToPlaylistEdit = actionCreator<ISong[]>(ActionTypes.ADD_SONGS_TO_PLAYLIST_EDIT);
export const deleteSongFromPlaylistEdit = actionCreator<number>(ActionTypes.DELETE_SONG_FROM_PLAYLIST_EDIT);
export const sortPlaylistEditSongs = actionCreator<ISortSong>(ActionTypes.SORT_PLAYLIST_EDIT_SONGS);

export const requestEditPlaylist = actionCreator<IEditPlaylistServer>(ActionTypes.REQUEST_EDIT_PLAYLIST);
export const successEditPlaylist = actionCreator<undefined>(ActionTypes.SUCCESS_EDIT_PLAYLIST);
export const failureEditPlaylist = actionCreator<AxiosError>(ActionTypes.FAILURE_EDIT_PLAYLIST);