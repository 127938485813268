import React, { Component } from 'react';

interface IAudioPreviewProps {
  path: string;
  playing: boolean;
  startSong: () => void;
  pauseSong: () => void;
}

class AudioPreview extends Component<IAudioPreviewProps, {}> {
  private audioRef: any;

  constructor(props: IAudioPreviewProps) {
    super(props);

    this.isPlaying = this.isPlaying.bind(this);
    this.handleEnded = this.handleEnded.bind(this);
  }

  componentDidUpdate = () => {
    if (this.props.playing) {
      this.audioRef.play().catch(() => { /**/ });
      this.isPlaying();
      } else {
      this.audioRef.pause();
    }
  }

  isPlaying() {
    if (this.audioRef) {
      const isPlaying = !this.audioRef.paused && !this.audioRef.ended && 0 < this.audioRef.currentTime;
      if (isPlaying) {
        this.props.startSong();
      }
      if (!isPlaying) {
        setTimeout(() => {
          this.isPlaying();
        }, 500);
      }
    }
  }

  handleEnded() {
    this.props.pauseSong();
  }

  render() {
    const { path } = this.props;

    return (
      <React.Fragment>
        <audio
          src={path}
          ref={(element: HTMLAudioElement) => this.audioRef = element}
          onEnded={this.handleEnded}
        />
      </React.Fragment>
    );
  }
}

export default AudioPreview;
