import React from 'react';

import './HeaderActions.scss';
import Button from '../../Button';

export interface IHeaderActionsProps {
  newList?: () => void;
  goToGenres?: () => void;
  save?: {
    disabled: boolean;
    submit: () => void;
  };
  cancel?: () => void;
  back?: () => void;
  addSongsToPlaylist?: {
    disabled: boolean;
    submit: () => void;
  };
}

const HeaderActions = (props: IHeaderActionsProps) => {
  const { newList, goToGenres, save, cancel, back, addSongsToPlaylist } = props;
  const content: JSX.Element[] = [];

  if (newList) {
    content.push(
      <Button
        key='newList'
        bgColor='green'
        text='Nueva Lista'
        click={newList}
      />
    );
  }

  if (goToGenres) {
    content.push(
      <Button
        key='goToGenres'
        bgColor='green'
        text='Agregar música'
        click={goToGenres}
      />
    );
  }

  if (save) {
    content.push(
      <Button
        key='save'
        bgColor='green'
        text='Guardar'
        click={save.submit}
        disabled={save.disabled}
      />
    );
  }

  if (cancel) {
    content.push(
      <Button key='cancel' bgColor='red' text='Cancelar' click={cancel} />
    );
  }

  if (back) {
    content.push(
      <Button key='back' bgColor='red' text='Volver' click={back} />
    );
  }

  if (addSongsToPlaylist) {
    content.push(
      <Button
        key='addSongsToPlaylist'
        bgColor='green'
        text='Agregar'
        disabled={addSongsToPlaylist.disabled}
        click={addSongsToPlaylist.submit}
      />
    );
  }

  return <div className='header-actions'>{content}</div>;
};

export default HeaderActions;
