import { connect } from 'react-redux';

import PlaylistDetails from './PlaylistDetails';
import {
  goToHome,
  requestPlaylistDetails,
  pauseSong,
} from '../../actions';

const mapStateToProps = (state: any) => ({
  loading: state.playlistDetails.loading,
  songs: state.playlistDetails.songs,
});

const mapDispatchToProps = {
  requestPlaylistDetails,
  goBack: goToHome,
  pauseSong,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlaylistDetails);
