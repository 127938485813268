import { Action } from 'typescript-fsa';

import ActionTypes from '../constants/ActionTypes';
import { convertServerPlaylist, convertServerPlaylists } from '../utils/converters';

export interface IClient {
  id: number;
  name: string;
}

export interface IPlaylist {
  id: number;
  name: string;
  createdAt: string;
  client: IClient;
}

export interface IPlaylistsState {
  loading: boolean,
  playlists: IPlaylist[];
}

const initialState: IPlaylistsState = {
  loading: false,
  playlists: []
};

export const playlists = (state = initialState, action: Action<any>) => {
  switch (action.type) {
    case ActionTypes.REQUEST_PLAYLISTS: {
      return {
        loading: true,
        playlists: [],
      };
    }
    case ActionTypes.SUCCESS_PLAYLISTS: {
      return {
        loading: false,
        playlists: convertServerPlaylists(action.payload)
      };
    }
    case ActionTypes.FAILURE_PLAYLISTS: {
      return initialState;
    }
    case ActionTypes.SUCCESS_DELETE_PLAYLIST: {
      const filteredPlaylists = state.playlists.filter((playlist) => playlist.id !== action.payload);
      return {
        loading: false,
        playlists: filteredPlaylists,
      };
    }
    case ActionTypes.SUCCESS_DUPLICATE_PLAYLIST: {
      const duplicatedPlaylist = convertServerPlaylist(action.payload);
      return {
        loading: false,
        playlists: [ ...state.playlists, duplicatedPlaylist ],
      };
    }
    default:
      return state;
  }
};
