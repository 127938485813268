import React from 'react';

import './loading-row.scss';
import './Row.scss';

type CellType =
  | 'text'
  | 'composedText'
  | 'action'
  | 'button';
type CellContent = string | JSX.Element;

export interface ICellProps {
  type: CellType;
  content: CellContent;
  width?: number;
}

export interface IRowProps {
  columns: ICellProps[];
  classes: string;
}

export function columnClasses(index: number, length: number, type: string): string {
  let classes: string = 'col';

  if (index === 0) {
    classes = 'col col-first';
  } else if (index === length - 1) {
    classes = 'col col-last';
  } else {
    classes = 'col';
  }

  if (type === 'composedText') {
    classes += ' col-composed';
  } else if (
    type === 'action' ||
    type === 'button'
  ) {
    classes += ' col-fixed';
  }

  return classes;
}

const Row = (props: IRowProps) => {
  const { columns, classes } = props;

  const content: JSX.Element[] = columns.map(
    (column: ICellProps, i: number) => {
      const colClasses: string = columnClasses(i, columns.length, column.type);

      const style = column.width
        ? { flexBasis: `${column.width}px`, flexGrow: 0 }
        : undefined;

      return (
        <div key={i} className={colClasses} style={style}>
          {column.content}
        </div>
      );
    }
  );

  return <div className={`row ${classes}`}>{content}</div>;
};

export default Row;
