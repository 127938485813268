import { Action } from 'typescript-fsa';

import ActionTypes from '../constants/ActionTypes';

export interface IAudioPreviewState {
  id: number | null;
  path: string;
  playing: boolean;
  loading: boolean;
}

const initialState: IAudioPreviewState = {
  id: null,
  path: '',
  playing: false,
  loading: false,
};

export const audioPreview = (state = initialState, action: Action<any>) => {
  switch (action.type) {
    case ActionTypes.PLAY_SONG: {
      return {
        id: action.payload.id,
        path: action.payload.path,
        playing: true,
        loading: true,
      };
    }
    case ActionTypes.START_SONG: {
      return {
        ...state,
        loading: false,
      };
    }
    case ActionTypes.PAUSE_SONG: {
      return initialState;
    }
    default:
      return state;
  }
};
