import { Action } from 'typescript-fsa';

import ActionTypes from '../constants/ActionTypes';
import { ISong } from './genreDetails';
import { convertServerSongs } from '../utils/converters';

export interface IEditPlaylistState {
  id: number;
  name: string;
  user_id: number;
  songs: ISong[];
  editing: boolean;
  loading: boolean;
  saving: boolean;
}

const initialState: IEditPlaylistState = {
  id: 0,
  name: '',
  user_id: 0,
  songs: [],
  editing: false,
  loading: false,
  saving: false,
};

export const editPlaylist = (state = initialState, action: Action<any>) => {
  switch (action.type) {
    case ActionTypes.REQUEST_PLAYLIST_EDIT_CONTENT: {
      return {
        ...initialState,
        editing: true,
        loading: true,
      };
    }

    case ActionTypes.SUCCESS_PLAYLIST_EDIT_CONTENT: {
      return {
        ...state,
        id: action.payload.id,
        user_id: action.payload.user_id,
        name: action.payload.name,
        songs: convertServerSongs(action.payload.content),
        loading: false,
      };
    }

    case ActionTypes.SET_PLAYLIST_EDIT_NAME: {
      return {
        ...state,
        name: action.payload,
      };
    }

    case ActionTypes.ADD_SONGS_TO_PLAYLIST_EDIT: {
      return {
        ...state,
        songs: [...state.songs, ...action.payload],
      };
    }

    case ActionTypes.SORT_PLAYLIST_EDIT_SONGS: {
      const songs = Array.from(action.payload.songs);
      const [removed] = songs.splice(action.payload.oldIndex, 1);
      songs.splice(action.payload.newIndex, 0, removed);
      return {
        ...state,
        songs,
      };
    }

    case ActionTypes.DELETE_SONG_FROM_PLAYLIST_EDIT: {
      const songs = state.songs.filter((song: ISong) => {
        if (song.id !== action.payload) {
          return song;
        }
      });
      return {
        ...state,
        songs,
      };
    }

    case ActionTypes.REQUEST_EDIT_PLAYLIST: {
      return {
        ...state,
        saving: true,
      };
    }

    case ActionTypes.GO_TO_HOME:
    case ActionTypes.SUCCESS_EDIT_PLAYLIST:
    case ActionTypes.FAILURE_EDIT_PLAYLIST: {
      return initialState;
    }

    default:
      return state;
  }
};
