import React from 'react';

import './Loading.scss';

const Loading = () => {
  return (
    <div className='loading'>
      <div className='loading-element'><div /><div/><div/><div/></div>
    </div>
  );
};

export default Loading;
