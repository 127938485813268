import React from 'react';

import Row, { ICellProps } from '../Row';
import Button from '../Button';

export interface IGenre {
  id: number;
  name: string;
  songsCount: number;
  goToDetails: () => void;
}

const Genre = (props: IGenre) => {
  const { name, songsCount, goToDetails } = props;

  const content: ICellProps[] = [
    {
      type: 'text',
      content: name,
    },
    {
      type: 'text',
      content: songsCount.toString(),
    },
    {
      type: 'button',
      content: (
        <Button bgColor='green' text='Ver' click={goToDetails} />
      ),
      width: 180,
    }
  ];
  return <Row columns={content} classes='song' />;
};

export default Genre;
