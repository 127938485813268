import { mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import axios from '../utils/axios';
import ActionTypes from '../constants/ActionTypes';
import { successPlaylists, failurePlaylists } from '../actions';

export const fetchPlaylists = (action$: any) => action$.pipe(
  ofType(ActionTypes.REQUEST_PLAYLISTS),
  mergeMap((action: any) => {
    const sortBy = action.payload.columnName;
    const orderBy = action.payload.orderAsc ? 'asc' : 'desc';
    return axios.get(`/schedules?sort_by=${sortBy}&order_by=${orderBy}`)
      .then((response) => successPlaylists(response.data))
      .catch((error) => failurePlaylists(error));
  }),
);
