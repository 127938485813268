import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Header from '../../components/Header';
import GridHeader from '../../components/GridHeader';
import Song, { ISong } from '../../components/Song';
import { IHeaderActionsProps } from '../../components/Header/HeaderActions';
import viewPlaylistHeaders from './headers';
import AudioPreview from '../../components/AudioPreview';
import Loading from '../../components/Loading';
import { IDragResult, IDroppableProvided, IDraggableProvided } from '../NewPlaylist/interfaces';
import { ISortSong, IEditPlaylistServer } from '../../actions';
import { numberToTime } from '../../utils/utils';
import Modal, { IModalProps } from '../../components/Modal';

interface IMatchParams {
  id: string;
}

interface IPlaylistEditProps extends RouteComponentProps<IMatchParams> {
  loading: boolean;
  editing: boolean;
  songs: ISong[];
  name: string;
  goBack: () => void;
  goToGenres: () => void;
  pauseSong: () => void;
  requestPlaylistEditContent: (id: string) => void;
  deleteSongFromPlaylistEdit: (id: number) => void;
  sortPlaylistEditSongs: (sortSong: ISortSong) => void;
  setPlaylistEditName: (name: string) => void;
  requestEditPlaylist: (playlist: IEditPlaylistServer) => void;
}

interface IPlaylistEditState {
  search: string;
  songs: ISong[];
  saveModal: boolean;
}

class PlaylistEdit extends Component<IPlaylistEditProps, IPlaylistEditState> {
  constructor(props: IPlaylistEditProps) {
    super(props);
    this.state = {
      search: '',
      songs: this.props.songs,
      saveModal: false,
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleSaveModal = this.handleSaveModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount() {
    if (!this.props.editing) {
      this.props.requestPlaylistEditContent(this.props.match.params.id);
    }
  }

  componentDidUpdate(prevProps: IPlaylistEditProps) {
    if (prevProps.songs.length !== this.props.songs.length) {
      this.setState({
        songs: this.props.songs,
      });
    }
  }

  componentWillUnmount() {
    this.props.pauseSong();
  }

  closeModal(): void {
    this.setState({
      saveModal: false,
    });
  }

  modalSaveContent(): IModalProps {
    return {
      close: this.closeModal,
      title: 'Guardar cambios',
      content: () => (
        <input
          value={this.props.name}
          onChange={this.handleNameChange}
        />
      ),
      buttons: [
        {
          bgColor: 'green',
          text: 'Guardar',
          click: this.handleSave,
        },
        {
          bgColor: 'red',
          text: 'Seguir editando',
          click: this.closeModal.bind(this),
        }
      ],
    };
  }

  handleNameChange(e: React.FormEvent<HTMLInputElement>): void {
    this.props.setPlaylistEditName(e.currentTarget.value);
  }

  handleSaveModal(): void {
    this.setState({
      saveModal: true,
    });
  }

  handleSearch(e: React.FormEvent<HTMLInputElement>): void {
    this.setState({
      search: e.currentTarget.value,
    });
  }

  handleSelect(id: number): void {
    const songs = this.props.songs.map((song: ISong) => {
      if (song.id === id) {
        song.selected = !song.selected;
      }
      return song;
    });
    this.setState({ songs });
  }

  handleSave(): void {
    const songs: number[] = this.state.songs.map(
      (song: ISong): number => song.id
    );
    const playlist = {
      id: +this.props.match.params.id,
      name: this.props.name,
      songs,
    };

    this.props.requestEditPlaylist(playlist);
  }

  handleGoToGenres(): void {
    this.props.goToGenres();
  }

  handleBack(): void {
    this.props.goBack();
  }

  handleDelete(id: number): void {
    this.props.deleteSongFromPlaylistEdit(id);
  }

  onDragEnd(result: IDragResult) {
    if (!result.destination) {
      return;
    }

    const sortSong = {
      songs: this.props.songs,
      oldIndex: result.source.index,
      newIndex: result.destination.index,
    };

    this.props.sortPlaylistEditSongs(sortSong);
  }

  getSongTemplate(song: ISong, index: number): JSX.Element {
    song.deleteFn = () => this.handleDelete(song.id);
    return (
      <Song
        key={index}
        id={song.id}
        name={song.name}
        path={song.path}
        artist={song.artist}
        genre={song.genre}
        duration={song.duration}
        deleteFn={song.deleteFn}
      />
    );
  }

  getDropContent(provided: IDroppableProvided) {
    return (
      <div
        ref={provided.innerRef}
        className='songs-grid'
      >
        {this.props.songs.map((song: ISong, index: number) => this.getMappedSong(song, index))}
        {provided.placeholder}
      </div>
    );
  }

  getMappedSong(song: ISong,  index: number): JSX.Element {
    return (
      <Draggable key={index} draggableId={index + 1} index={index}>
        {(providedElement: IDraggableProvided) => this.getDragContent(song, index, providedElement)}
      </Draggable>
    );
  }

  getDragContent(song: ISong, index: number, providedElement: IDraggableProvided): JSX.Element {
    return (
      <div
        ref={providedElement.innerRef}
        {...providedElement.draggableProps}
        {...providedElement.dragHandleProps}
        className='row-container'
      >
        {this.getSongTemplate(song, index)}
      </div>
    );
  }

  getPlaylistLength(): JSX.Element | null {
    if (this.props.songs.length > 0) {
      const length = numberToTime(this.props.songs.reduce((acc: number, song: ISong) => acc + song.duration, 0));
      return <p className='playlistLength'>Duración total: {length}</p>;
    } else {
      return null;
    }
  }

  render() {
    const actions: IHeaderActionsProps = {
      goToGenres: this.handleGoToGenres.bind(this),
      save: {
        disabled: this.state.songs.length > 0 ? false : true,
        submit: this.handleSaveModal,
      },
      back: this.handleBack,
    };

    const modal = this.state.saveModal ? (
      <Modal {...this.modalSaveContent()} />
    ) : null;

    const content = (this.props.songs.length > 0)
      ? (
        <SimpleBar>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId='droppable'>
                {(provided: IDroppableProvided) => this.getDropContent(provided)}
              </Droppable>
            </DragDropContext>
          </SimpleBar>
      )
      : <Loading />;
    return (
      <React.Fragment>
        {modal}
        <AudioPreview />
        <Header
          search={{ value: this.state.search, handleSearch: this.handleSearch }}
          actions={actions}
        />
        <section>
          {this.getPlaylistLength()}
          <GridHeader columns={viewPlaylistHeaders} />
          {content}
        </section>
      </React.Fragment>
    );
  }
}

export default withRouter(PlaylistEdit);
