import axios, { AxiosResponse, AxiosError } from 'axios';

export const BASE_URL: string = 'http://api.new.nuvelar.com/public/api/v1';

const instance = axios.create({
  baseURL: BASE_URL,
});

function errorResponseHandler(error: AxiosError) {
  if (error.response) {
    alert(error.response.data.error);
  }
  return Promise.reject(error);
}

instance.interceptors.response.use(
  (response: AxiosResponse) => response,
  errorResponseHandler
);

export default instance;
