import { mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import axios from '../utils/axios';
import ActionTypes from '../constants/ActionTypes';
import { successPlaylistDetails, failurePlaylistDetails } from '../actions';

export const fetchPlaylistDetails = (action$: any) => action$.pipe(
  ofType(ActionTypes.REQUEST_PLAYLIST_DETAILS),
  mergeMap(({ payload }) =>
    axios.get(`/playlists/${payload}`)
      .then((response) => successPlaylistDetails(response.data))
      .catch((error) => failurePlaylistDetails(error))
    )
);
