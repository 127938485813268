import { mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';

import axios from '../utils/axios';
import ActionTypes from '../constants/ActionTypes';
import { successCreatePlaylist, failureCreatePlaylist, goToHome } from '../actions';

export const savePlaylist = (action$: any) => action$.pipe(
  ofType(ActionTypes.REQUEST_CREATE_PLAYLIST),
  mergeMap((action: any) =>
    axios.post('/playlists', action.payload)
      .then(() => successCreatePlaylist())
      .catch((error) => failureCreatePlaylist(error))
    )
);

export const showCreatedPlaylist = (action$: any) => action$.pipe(
  ofType(ActionTypes.SUCCESS_CREATE_PLAYLIST),
  mergeMap(() => of(goToHome())),
);
