// tslint:disable:no-empty-interface
import React, { Component } from 'react';
import { isEqual } from 'lodash';

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import Header from '../../components/Header';
import GridHeader, { IGridHeaderCell } from '../../components/GridHeader';
import Genre from '../../components/Genre/Genre';
import { IHeaderActionsProps } from '../../components/Header/HeaderActions';
import { IGenre } from '../../reducers/genres';
import Loading from '../../components/Loading';

interface IGenresListProps {
  loading: boolean;
  genres: IGenre[];
  columnOrder: any;
  isEditing: boolean;
  editingId: number;
  requestGenres: (columnOrder: any) => void;
  goToNewPlaylist: () => void;
  goToPlaylistEdit: (id: string) => void;
  goToGenreDetails: (genreId: string) => void;
  sortGenresBy: (columnName: string) => void;
}

interface IGenresListState {
  search: string;
}

class GenresList extends Component<IGenresListProps, IGenresListState> {
  constructor(props: IGenresListProps) {
    super(props);

    this.state = {
      search: '',
    };

    this.handleGoToDetails = this.handleGoToDetails.bind(this);
  }

  componentDidMount() {
    this.props.requestGenres(this.props.columnOrder);
  }

  componentDidUpdate(prevProps: IGenresListProps) {
    if (!isEqual(prevProps.columnOrder, this.props.columnOrder)) {
      this.props.requestGenres(this.props.columnOrder);
    }
  }

  handleSearch(e: React.FormEvent<HTMLInputElement>): void {
    this.setState({
      search: e.currentTarget.value,
    });
  }

  handleBack(): void {
    if (this.props.isEditing) {
      this.props.goToPlaylistEdit(this.props.editingId.toString());
    } else {
      this.props.goToNewPlaylist();
    }
  }

  handleGoToDetails(id: number): void {
    this.props.goToGenreDetails(id.toString());
  }

  render() {
    const actions: IHeaderActionsProps = {
      back: this.handleBack.bind(this),
    };

    const headers: IGridHeaderCell[] = [
      {
        width: 'dynamic',
        content: 'Nombre',
      },
      {
        width: 'dynamic',
        content: 'Canciones',
      },
      {
        width: 180,
        content: '',
      }
    ];

    const genres = this.props.genres
      .filter((genre: IGenre) =>
        genre.name.toLowerCase().includes(this.state.search.toLowerCase())
      )
      .map((genre: IGenre, index: number) => (
        <Genre
          key={index}
          id={genre.id}
          name={genre.name}
          songsCount={genre.songsCount}
          // tslint:disable-next-line:no-console
          // tslint:disable-next-line:jsx-no-lambda
          // tslint:disable-next-line:max-line-length
          goToDetails={() => this.handleGoToDetails(genre.id)}
        />
      ));

    const content = (this.props.genres.length > 0)
      ? <SimpleBar style={{ height: '100%' }}>{genres}</SimpleBar>
      : <Loading />;
    return (
      <React.Fragment>
        <Header
          search={{ value: this.state.search, handleSearch: this.handleSearch.bind(this) }}
          actions={actions}
        />
        <section>
          <GridHeader sortBy={this.props.sortGenresBy} columns={headers} />
          {content}
        </section>
      </React.Fragment>
    );
  }
}

export default GenresList;
