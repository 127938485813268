// tslint:disable:jsx-no-lambda
import React from 'react';

import Button, { IButtonProps } from '../Button';

import './Modal.scss';

export interface IModalProps {
  title: string;
  buttons: IButtonProps[];
  close: () => void;
  content?: () => JSX.Element;
}

const Modal = (props: IModalProps) => {
  const { close, title, content, buttons } = props;

  const buttonsContent: JSX.Element[] = buttons.map(
    (button: IButtonProps, i: number) => {
      return (
        <Button
          key={i}
          bgColor={button.bgColor}
          text={button.text}
          click={button.click}
          disabled={button.disabled}
        />
      );
    }
  );

  const contentTemplate = content ? content() : null;

  return (
    <div className='modal-background' onClick={close}>
      <div className='modal' onClick={(e) => e.stopPropagation()}>
        <h3>{title}</h3>
        <div className='modal-content'>{contentTemplate}</div>
        <div className='modal-buttons'>{buttonsContent}</div>
      </div>
    </div>
  );
};

export default Modal;
