import React from 'react';

import './Header.scss';
import Search, { ISearchProps } from './Search';
import HeaderActions, { IHeaderActionsProps } from './HeaderActions';

interface IHeaderProps {
  search: ISearchProps;
  actions: IHeaderActionsProps;
}

const Header = (props: IHeaderProps) => {
  const { search, actions } = props;
  return (
    <div className='header'>
      <Search value={search.value} handleSearch={search.handleSearch} />
      <HeaderActions {...actions} />
    </div>
  );
};

export default Header;
