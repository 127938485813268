import { connect } from 'react-redux';

import Song from './Song';
import {
  playSong,
  pauseSong
} from '../../actions';

const mapStateToProps = (state: any) => ({
  activeId: state.audioPreview.id,
  playing: state.audioPreview.playing,
  loading: state.audioPreview.loading,
});

const mapDispatchToProps = {
  playSong,
  pauseSong,
};

export * from './Song';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Song);
