import React from 'react';

import './Search.scss';

export interface ISearchProps {
  value: string;
  handleSearch: (e: React.FormEvent<HTMLInputElement>) => void;
}

const Search = (props: ISearchProps) => {
  const { value, handleSearch } = props;
  return (
    <input
      className='search'
      value={value}
      // tslint:disable-next-line:jsx-no-lambda
      onChange={(e) => handleSearch(e)}
      placeholder='Buscar'
    />
  );
};

export default Search;
