import { IGridHeaderCell } from '../../components/GridHeader';

const genreDetailsHeaders: IGridHeaderCell[] = [
  {
    width: 'fixed',
    content: '',
  },
  {
    width: 'fixed',
    content: '',
  },
  {
    width: 'dynamic',
    content: 'Título',
  },
  {
    width: 'dynamic',
    content: 'Artista',
  },
  {
    width: 'dynamic',
    content: 'Género',
  },
  {
    width: 'dynamic',
    content: 'Duración',
  }
];

export default genreDetailsHeaders;
