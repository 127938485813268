import { Action } from 'typescript-fsa';

import ActionTypes from '../constants/ActionTypes';
import { convertServerSongs } from '../utils/converters';

export interface ISongGenre {
  id: number;
  name: string;
}

export interface ISong {
  id: number;
  name: string;
  path: string;
  artist: string;
  genre: ISongGenre;
  duration: number;
}

export interface IGenreDetailsState {
  loading: boolean;
  songs: ISong[];
};

const initialState: IGenreDetailsState = {
  loading: false,
  songs: [],
};

export const genreDetails = (state = initialState, action: Action<any>) => {
  switch (action.type) {
    case ActionTypes.SUCCESS_GENRE_DETAILS: {
      return {
        loading: false,
        songs: convertServerSongs(action.payload),
      };
    }
    case ActionTypes.REQUEST_GENRE_DETAILS: {
      return {
        loading: true,
        songs: [],
      };
    }
    case ActionTypes.FAILURE_GENRE_DETAILS: {
      return initialState;
    }
    default:
      return state;
  }
};
