import * as React from 'react';
import { History } from 'history';
import { Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';

import * as Routes from './constants/routes';
import Login from './components/Login';
import PlaylistsList from './containers/PlaylistsList';
import NewPlaylist from './containers/NewPlaylist';
import GenresList from './containers/GenresList';
import GenreDetails from './containers/GenreDetails';
import PlaylistDetails from './containers/PlaylistDetails';
import PlaylistEdit from './containers/PlaylistEdit';

interface IRouter {
  history: History;
}

const Router = ({ history }: IRouter ) => (
  <ConnectedRouter history={history}>
    <Switch>
      <Route exact={true} path={Routes.login()} component={Login} />
      <Route exact={true} path={Routes.home()} component={PlaylistsList} />
      <Route exact={true} path={Routes.createPlaylist()} component={NewPlaylist} />
      <Route exact={true} path={Routes.genres()} component={GenresList} />
      <Route exact={true} path={Routes.genreDetails(':id')} component={GenreDetails} />
      <Route exact={true} path={Routes.playlistDetails(':id')} component={PlaylistDetails} />
      <Route exact={true} path={Routes.playlistEdit(':id')} component={PlaylistEdit} />
    </Switch>
  </ConnectedRouter>
);

export default Router;
