import { mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';

import axios from '../utils/axios';
import ActionTypes from '../constants/ActionTypes';

import { successUsers, failureUsers } from '../actions';

export const fetchUsers = (action$: any) => action$.pipe(
  ofType(ActionTypes.REQUEST_USERS),
  mergeMap(() =>
    axios.get('/users')
      .then((response) => successUsers(response.data))
      .catch((error) => of(failureUsers(error)))
    )
);
