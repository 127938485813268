import { connect } from 'react-redux';

import PlaylistList from './PlaylistsList';
import {
  requestUsers,
  requestPlaylists,
  requestDeletePlaylist,
  requestDuplicatePlaylist,
  goToNewPlaylist,
  goToPlaylistDetails,
  goToPlaylistEdit,
  sortPlaylistsBy,
} from '../../actions';

const mapStateToProps = (state: any) => ({
  users: state.data.users,
  loading: state.data.playlists.loading,
  playlists: state.data.playlists.playlists,
  columnOrder: state.ui.columnOrder.playlists,
});

const mapDispatchToProps = {
  requestPlaylists,
  requestUsers,
  goToNewPlaylist,
  goToPlaylistDetails,
  goToPlaylistEdit,
  sortPlaylistsBy,
  deletePlaylist: requestDeletePlaylist,
  duplicatePlaylist: requestDuplicatePlaylist,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlaylistList);
