export function numberToTime(seconds: number) {
  return new Date(seconds * 1000).toISOString().substr(11, 8);
}

export function formatDate(date: string): string {
  const originalDate = new Date(date);
  const day = ('0' + originalDate.getDate()).slice(-2);
  const month = ('0' + originalDate.getMonth()).slice(-2);
  const year = ('0' + originalDate.getFullYear()).slice(-4);
  const hour = ('0' + originalDate.getHours()).slice(-2);
  const minutes = ('0' + originalDate.getMinutes()).slice(-2);
  return `${day}-${month}-${year}  ${hour}:${minutes}`;
}
