import { Action } from 'typescript-fsa';

import ActionTypes from '../constants/ActionTypes';
import { ISong } from '../components/Song';
import { ALL_USERS_VALUE } from '../constants/users';

export interface ICreatePlaylistState {
  name: string;
  user_id: number;
  songs: ISong[];
  saving: boolean;
}

const initialState: ICreatePlaylistState = {
  name: '',
  user_id: ALL_USERS_VALUE,
  songs: [],
  saving: false,
};

export const createPlaylist = (state = initialState, action: Action<any>) => {
  switch (action.type) {
    case ActionTypes.SET_PLAYLIST_USER: {
      return {
        ...state,
        user_id: action.payload,
      };
    }

    case ActionTypes.SET_PLAYLIST_NAME: {
      return {
        ...state,
        name: action.payload,
      };
    }

    case ActionTypes.ADD_SONGS_TO_PLAYLIST: {
      return {
        ...state,
        songs: [...state.songs, ...action.payload],
      };
    }

    case ActionTypes.SORT_PLAYLIST_SONGS: {
      const songs = Array.from(action.payload.songs);
      const [removed] = songs.splice(action.payload.oldIndex, 1);
      songs.splice(action.payload.newIndex, 0, removed);
      return {
        ...state,
        songs,
      };
    }

    case ActionTypes.DELETE_SONG_FROM_PLAYLIST: {
      const songs = state.songs.filter((song: ISong) => {
        if (song.id !== action.payload) {
          return song;
        }
      });
      return {
        ...state,
        songs,
      };
    }

    case ActionTypes.REQUEST_CREATE_PLAYLIST: {
      return {
        ...state,
        saving: true,
      };
    }

    case ActionTypes.SUCCESS_CREATE_PLAYLIST:
    case ActionTypes.FAILURE_CREATE_PLAYLIST:
    case ActionTypes.RESET_PLAYLIST: {
      return initialState;
    }

    default:
      return state;
  }
};
