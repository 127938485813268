import { Action } from 'typescript-fsa';

import ActionTypes from '../constants/ActionTypes';
import { convertServerUsers } from '../utils/converters';

export interface IUser {
  id: number;
  name: string;
}

const initialState: IUser[] = [];

export const users = (state = initialState, action: Action<any>) => {
  switch (action.type) {
    case ActionTypes.SUCCESS_USERS: {
      return convertServerUsers(action.payload);
    }
    case ActionTypes.FAILURE_USERS: {
      return [];
    }
    default:
      return state;
  }
};
