import React, { Component } from 'react';

import './Login.scss';
import Button from '../Button';

interface ILoginProps {
  submit: () => void;
}

interface ILoginState {
  email: string;
  password: string;
}

class Login extends Component<ILoginProps, ILoginState> {
  constructor(props: ILoginProps) {
    super(props);

    this.state = {
      email: '',
      password: '',
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleEmailChange(e: React.FormEvent<HTMLInputElement>): void {
    this.setState({
      email: e.currentTarget.value,
    });
  }

  handlePasswordChange(e: React.FormEvent<HTMLInputElement>): void {
    this.setState({
      password: e.currentTarget.value,
    });
  }

  handleSubmit() {
    this.props.submit();
  }

  render() {
    return (
      <section className='login'>
        <div className='login-container'>
          <div className='form-element'>
            <label htmlFor=''>Email</label>
            <input
              type='text'
              value={this.state.email}
              onChange={this.handleEmailChange}
              className='email'
            />
          </div>
          <div className='form-element'>
            <label htmlFor=''>Contraseña</label>
            <input
              type='password'
              value={this.state.password}
              onChange={this.handlePasswordChange}
              className='password'
            />
          </div>
          <div className='form-element'>
            <Button bgColor='green' text='Ingresar' click={this.handleSubmit} />
          </div>
        </div>
      </section>
    );
    }
}

export default Login;
