/**
 * Utility function to create a K:V from a list of strings
 * https://basarat.gitbooks.io/typescript/docs/types/literal-types.html
 */
function strEnum<T extends string>(o: T[]): {[K in T]: K} {
  return o.reduce((res, key) => {
    res[key] = key;
    return res;
  }, Object.create(null));
}

export default strEnum([
  // UI Routes
  'GO_TO_HOME',
  'GO_TO_GENRES',
  'GO_TO_NEW_PLAYLIST',
  'GO_TO_GENRE_DETAILS',
  'GO_TO_PLAYLIST_DETAILS',
  'GO_TO_PLAYLIST_EDIT',

  // UI Sort by
  'SORT_PLAYLISTS_BY',
  'SORT_GENRES_BY',
  'SORT_SONGS_BY',

  'REQUEST_PLAYLISTS',
  'SUCCESS_PLAYLISTS',
  'FAILURE_PLAYLISTS',

  'REQUEST_DELETE_PLAYLIST',
  'SUCCESS_DELETE_PLAYLIST',
  'FAILURE_DELETE_PLAYLIST',

  'REQUEST_DUPLICATE_PLAYLIST',
  'SUCCESS_DUPLICATE_PLAYLIST',
  'FAILURE_DUPLICATE_PLAYLIST',

  'REQUEST_GENRES',
  'SUCCESS_GENRES',
  'FAILURE_GENRES',

  'REQUEST_GENRE_DETAILS',
  'SUCCESS_GENRE_DETAILS',
  'FAILURE_GENRE_DETAILS',

  'REQUEST_USERS',
  'SUCCESS_USERS',
  'FAILURE_USERS',

  'SET_PLAYLIST_USER',
  'SET_PLAYLIST_NAME',
  'ADD_SONGS_TO_PLAYLIST',
  'DELETE_SONG_FROM_PLAYLIST',
  'SORT_PLAYLIST_SONGS',
  'RESET_PLAYLIST',

  'REQUEST_CREATE_PLAYLIST',
  'SUCCESS_CREATE_PLAYLIST',
  'FAILURE_CREATE_PLAYLIST',

  'REQUEST_PLAYLIST_DETAILS',
  'SUCCESS_PLAYLIST_DETAILS',
  'FAILURE_PLAYLIST_DETAILS',

  'PLAY_SONG',
  'PAUSE_SONG',
  'START_SONG',

  'REQUEST_PLAYLIST_EDIT_CONTENT',
  'SUCCESS_PLAYLIST_EDIT_CONTENT',
  'FAILURE_PLAYLIST_EDIT_CONTENT',

  'SET_PLAYLIST_EDIT_DATA',
  'SET_PLAYLIST_EDIT_NAME',
  'ADD_SONGS_TO_PLAYLIST_EDIT',
  'DELETE_SONG_FROM_PLAYLIST_EDIT',
  'SORT_PLAYLIST_EDIT_SONGS',

  'REQUEST_EDIT_PLAYLIST',
  'SUCCESS_EDIT_PLAYLIST',
  'FAILURE_EDIT_PLAYLIST',
]);
