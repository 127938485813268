import React from 'react';

import './GridHeader.scss';

export interface IGridHeaderCell {
  width: 'dynamic' | 'fixed' | number;
  content: string;
}

interface IGridHeaderProps {
  columns: IGridHeaderCell[];
  sortBy?: (columnName: string) => void;
}

const GridHeader = (props: IGridHeaderProps) => {
  const columns: JSX.Element[] = props.columns.map(
    (col: IGridHeaderCell, i: number) => {
      const classes: string =
        i === 0
          ? 'col col-first'
          : i === props.columns.length - 1
          ? 'col col-last'
          : 'col';

      const widthClass = col.width === 'fixed' ? `${classes} col-fixed` : '';
      const style =
        typeof col.width === 'number'
          ? { flexBasis: `${col.width}px`, flexGrow: 0 }
          : undefined;

      return (
        // tslint:disable-next-line:no-console
        // tslint:disable-next-line:jsx-no-lambda
        // tslint:disable-next-line:max-line-length
        <div key={i} className={`${classes} ${widthClass}`} style={style} onClick={() => props.sortBy && props.sortBy(col.content)}>
          {col.content}
        </div>
      );
    }
  );
  return <header className='gridHeader'>{columns}</header>;
};

export default GridHeader;
