import { mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';

import axios from '../utils/axios';
import ActionTypes from '../constants/ActionTypes';
import {
  successEditPlaylist,
  failureEditPlaylist,
  successPlaylistEditContent,
  failurePlaylistEditContent,
  goToHome
} from '../actions';

export const requestPlaylistEditContent = (action$: any) => action$.pipe(
  ofType(ActionTypes.REQUEST_PLAYLIST_EDIT_CONTENT),
  mergeMap(({ payload }) =>
    axios.get(`/playlists/${payload}/edit`)
      .then((response) => successPlaylistEditContent(response.data))
      .catch((error) => failurePlaylistEditContent(error))
    )
);

export const savePlaylist = (action$: any) => action$.pipe(
  ofType(ActionTypes.REQUEST_EDIT_PLAYLIST),
  mergeMap((action: any) =>
    axios.put(`/playlists/${action.payload.id}`, {name: action.payload.name, songs: action.payload.songs})
      .then(() => successEditPlaylist())
      .catch((error) => failureEditPlaylist(error))
    )
);

export const showCreatedPlaylist = (action$: any) => action$.pipe(
  ofType(ActionTypes.SUCCESS_EDIT_PLAYLIST),
  mergeMap(() => of(goToHome())),
);
