import { connect } from 'react-redux';

import GenreDetails from './GenreDetails';
import {
  requestGenreDetails,
  addSongsToPlaylist,
  addSongsToPlaylistEdit,
  goToNewPlaylist,
  goToPlaylistEdit,
  goToGenres,
  sortSongsBy,
  pauseSong
} from '../../actions';

const mapStateToProps = (state: any) => ({
  loading: state.genreDetails.loading,
  songs: state.genreDetails.songs,
  columnOrder: state.ui.columnOrder.songs,
  isEditing: state.editPlaylist.editing,
  editingId: state.editPlaylist.id,
});

const mapDispatchToProps = {
  requestGenreDetails,
  addSongsToPlaylist,
  addSongsToPlaylistEdit,
  goToNewPlaylist,
  goToPlaylistEdit,
  goToGenres,
  sortSongsBy,
  pauseSong,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenreDetails);

