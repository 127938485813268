import { connect } from 'react-redux';

import NewPlaylist from './NewPlaylist';
import {
  requestUsers,
  setPlaylistUser,
  setPlaylistName,
  sortPlaylistSongs,
  deleteSongFromPlaylist,
  requestCreatePlaylist,
  resetPlaylist,
  goToHome,
  goToGenres,
  pauseSong,
} from '../../actions';

const mapStateToProps = (state: any) => ({
  user_id: state.createPlaylist.user_id,
  name: state.createPlaylist.name,
  songs: state.createPlaylist.songs,
  savingPlaylist: state.createPlaylist.saving,
  users: state.data.users,
});

const mapDispatchToProps = {
  setPlaylistUser,
  setPlaylistName,
  requestUsers,
  sortPlaylistSongs,
  deleteSongFromPlaylist,
  requestCreatePlaylist,
  resetPlaylist,
  goToHome,
  goToGenres,
  pauseSong,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPlaylist);
