import { Action } from 'typescript-fsa';
import { merge } from 'lodash';

import ActionTypes from '../constants/ActionTypes';

interface IUiColumnOrder {
  columnName: string;
  orderAsc: boolean;
}

export interface IUiState {
  columnOrder: {
    playlists: IUiColumnOrder;
    genres: IUiColumnOrder;
    songs: IUiColumnOrder;
  };
}

const initialState: IUiState = {
  columnOrder: {
    playlists: { columnName: 'playlist_name', orderAsc: true },
    genres: { columnName: 'name', orderAsc: true },
    songs: { columnName: 'name', orderAsc: true },
  },
};

const playlistColumnNameMap: any = {
  Nombre: 'playlist_name',
  Duración: 'duration',
  Cliente: 'first_name',
};

const genreColumnNameMap: any = {
  Nombre: 'name',
  Canciones: 'songs_count',
};

const songColumnNameMap: any = {
  Título: 'name',
  Artista: 'artist',
  Género: 'genre_name',
  Duración: 'duration',
};

export const uiReducer = (state = initialState, action: Action<any>) => {
  switch (action.type) {
    case ActionTypes.SORT_PLAYLISTS_BY: {
      const columnName = playlistColumnNameMap[action.payload];
      const orderAsc = !state.columnOrder.playlists.orderAsc;
      return merge(
        {},
        state,
        { columnOrder: { playlists: { columnName, orderAsc } } },
      );
    }
    case ActionTypes.SORT_GENRES_BY: {
      const columnName = genreColumnNameMap[action.payload];
      const orderAsc = !state.columnOrder.genres.orderAsc;
      return merge(
        {},
        state,
        { columnOrder: { genres: { columnName, orderAsc } } },
      );
    }
    case ActionTypes.SORT_SONGS_BY: {
      const columnName = songColumnNameMap[action.payload];
      const orderAsc = !state.columnOrder.songs.orderAsc;
      return merge(
        {},
        state,
        { columnOrder: { songs: { columnName, orderAsc } } },
      );
    }
    default:
      return state;
  }
};
