import { connect } from 'react-redux';

import GenresList from './GenresList';
import {
  requestGenres,
  goToNewPlaylist,
  goToPlaylistEdit,
  goToGenreDetails,
  sortGenresBy,
} from '../../actions';

const mapStateToProps = (state: any) => ({
  loading: state.data.genres.loading,
  genres: state.data.genres.genres,
  columnOrder: state.ui.columnOrder.genres,
  isEditing: state.editPlaylist.editing,
  editingId: state.editPlaylist.id,
});

const mapDispatchToProps = {
  requestGenres,
  goToNewPlaylist,
  goToPlaylistEdit,
  goToGenreDetails,
  sortGenresBy,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenresList);
