import { mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import axios from '../utils/axios';
import ActionTypes from '../constants/ActionTypes';
import {
  successDeletePlaylist,
  failureDeletePlaylist,
  successDuplicatePlaylist,
  failureDuplicatePlaylist
} from '../actions';

export const deletePlaylist = (action$: any) => action$.pipe(
  ofType(ActionTypes.REQUEST_DELETE_PLAYLIST),
  mergeMap((action: any) =>
    axios.delete(`/playlists/${action.payload}`)
      .then(() => successDeletePlaylist(action.payload))
      .catch((error) => failureDeletePlaylist(error))
    )
);

export const duplicatePlaylist = (action$: any) => action$.pipe(
  ofType(ActionTypes.REQUEST_DUPLICATE_PLAYLIST),
  mergeMap((action: any) =>
    axios.post('/playlists/duplicate', action.payload)
      .then((response) => successDuplicatePlaylist(response.data))
      .catch((error) => failureDuplicatePlaylist(error))
    )
);
