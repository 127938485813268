import React, { Component } from 'react';
import { isEqual } from 'lodash';

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import Header from '../../components/Header';
import GridHeader, { IGridHeaderCell } from '../../components/GridHeader';
import Playlist, { IPlaylist } from '../../components/Playlist';
import { IHeaderActionsProps } from '../../components/Header/HeaderActions';
import Modal, { IModalProps } from '../../components/Modal';
import { IDuplicatePlaylistServer } from '../../actions';
import { IUser } from '../../reducers/users';
import { ALL_USERS_VALUE } from '../../constants/users';
import Loading from '../../components/Loading';

interface IPlaylistsListProps {
  loading: boolean;
  playlists: IPlaylist[];
  users: IUser[];
  user_id: number;
  columnOrder: any;
  goToNewPlaylist: () => void;
  goToPlaylistDetails: (id: string) => void;
  goToPlaylistEdit: (id: string) => void;
  requestUsers: () => void;
  requestPlaylists: (columnOrder: any) => void;
  duplicatePlaylist: (playlist: IDuplicatePlaylistServer) => void;
  deletePlaylist: (id: number) => void;
  sortPlaylistsBy: (columnName: string) => void;
}

interface IPlaylistsListState {
  search: string;
  users: IUser[];
  user_id: number;
  duplicateModal: boolean;
  deleteModal: boolean;
  clickedPlaylist: number;
}

class PlaylistsList extends Component<IPlaylistsListProps, IPlaylistsListState> {
  constructor(props: IPlaylistsListProps) {
    super(props);

    this.state = {
      search: '',
      duplicateModal: false,
      deleteModal: false,
      clickedPlaylist: 0,
      users: this.filterUsers(),
      user_id: ALL_USERS_VALUE,
    };

    this.closeModal = this.closeModal.bind(this);
    this.confirmDeletePlaylist = this.confirmDeletePlaylist.bind(this);
    this.confirmDuplicatePlaylist = this.confirmDuplicatePlaylist.bind(this);
    this.handleDuplicatePlaylist = this.handleDuplicatePlaylist.bind(this);
  }

  componentDidMount() {
    this.props.requestPlaylists(this.props.columnOrder);
    this.props.requestUsers();
  }

  componentDidUpdate(prevProps: IPlaylistsListProps) {
    if (prevProps.users.length !== this.props.users.length) {
      const filteredUsers = this.filterUsers();
      this.setState({
        users: filteredUsers,
        user_id: filteredUsers[0].id,
      });
    }

    if (!isEqual(prevProps.columnOrder, this.props.columnOrder)) {
      this.props.requestPlaylists(this.props.columnOrder);
    }
  }

  filterUsers() {
    return this.props.users.filter((user: IUser) => user.id !== ALL_USERS_VALUE);
  }

  modalDeleteContent(): IModalProps {
    return {
      close: this.closeModal,
      title: 'Eliminar',
      content: () => <p>¿Está seguro de que desea eliminar la playlist?</p>,
      buttons: [
        {
          bgColor: 'green',
          text: 'Si',
          click: this.confirmDeletePlaylist,
        },
        {
          bgColor: 'red',
          text: 'No',
          click: this.closeModal,
        }
      ],
    };
  }

  modalDuplicateContent(): IModalProps {
    const userOptions: JSX.Element[] = this.state.users.map(
      (user: IUser, i: number) => (
        <option key={i} value={user.id}>
          {user.name}
        </option>
      )
    );

    return {
      close: this.closeModal,
      title: 'Duplicar lista',
      content: () => (
        <select
          id='user'
          // tslint:disable-next-line:jsx-no-bind
          onChange={this.handleUserChange.bind(this)}
          value={this.props.user_id}
        >
          {userOptions}
        </select>
      ),
      buttons: [
        {
          bgColor: 'green',
          text: 'Confirmar',
          click: this.confirmDuplicatePlaylist,
        },
        {
          bgColor: 'red',
          text: 'Cancelar',
          click: this.closeModal,
        }
      ],
    };
  }

  closeModal(): void {
    this.setState({
      duplicateModal: false,
      deleteModal: false,
    });
  }

  handleUserChange(e: React.FormEvent<HTMLSelectElement>): void {
    this.setState({
      user_id: +e.currentTarget.value,
    });
  }

  handleDeletePlaylist(id: number): void {
    this.setState({
      clickedPlaylist: id,
      deleteModal: true,
    });
  }

  handleDuplicatePlaylist(id: number): void {
    this.setState({
      clickedPlaylist: id,
      duplicateModal: true,
    });
  }

  confirmDuplicatePlaylist(): void {
    this.props.duplicatePlaylist({ playlist_id: this.state.clickedPlaylist, user_id: this.state.user_id });
    this.closeModal();
  }

  confirmDeletePlaylist(): void {
    this.props.deletePlaylist(this.state.clickedPlaylist);
    this.closeModal();
  }

  handleSearch(e: React.FormEvent<HTMLInputElement>): void {
    this.setState({
      search: e.currentTarget.value,
    });
  }

  handleNewPlaylist(): void {
    this.props.goToNewPlaylist();
  }

  render() {
    const actions: IHeaderActionsProps = {
      newList: this.handleNewPlaylist.bind(this),
    };

    const headers: IGridHeaderCell[] = [
      {
        width: 'dynamic',
        content: 'Nombre',
      },
      {
        width: 'dynamic',
        content: 'Duración',
      },
      {
        width: 'dynamic',
        content: 'Cliente',
      },
      {
        width: 'fixed',
        content: '',
      },
      {
        width: 'fixed',
        content: '',
      },
      {
        width: 'fixed',
        content: '',
      }
    ];

    const playlists = this.props.playlists
      .filter(
        (playlist: IPlaylist) =>
          playlist.name
            .toLowerCase()
            .includes(this.state.search.toLowerCase()) ||
          playlist.client.name
            .toLowerCase()
            .includes(this.state.search.toLowerCase())
      )
      .map((playlist: IPlaylist, index: number) => {
        playlist.duplicateFn = () => this.handleDuplicatePlaylist(playlist.id);
        playlist.deleteFn = () => this.handleDeletePlaylist(playlist.id);
        playlist.editFn = () => this.props.goToPlaylistEdit(playlist.id.toString());
        playlist.detailsFn = () => this.props.goToPlaylistDetails(playlist.id.toString());
        return (
        <Playlist
          key={index}
          id={playlist.id}
          name={playlist.name}
          createdAt={playlist.createdAt}
          duration={playlist.duration}
          client={playlist.client}
          family={playlist.family}
          editFn={playlist.editFn}
          detailsFn={playlist.detailsFn}
          duplicateFn={playlist.duplicateFn}
          deleteFn={playlist.deleteFn}
        />);
      });

    const modal = this.state.duplicateModal ? (
      <Modal {...this.modalDuplicateContent()} />
    ) : this.state.deleteModal ? (
      <Modal {...this.modalDeleteContent()} />
    ) : null;

    const content = (!this.props.loading)
      ? <SimpleBar style={{ height: '100%' }}>{playlists}</SimpleBar>
      : <Loading />;

    return (
      <React.Fragment>
        {modal}
        <Header
          search={{ value: this.state.search, handleSearch: this.handleSearch.bind(this) }}
          actions={actions}
        />
        <section>
          <GridHeader sortBy={this.props.sortPlaylistsBy} columns={headers} />
          {content}
        </section>
      </React.Fragment>
    );
  }
}

export default PlaylistsList;
