import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import Header from '../../components/Header';
import GridHeader from '../../components/GridHeader';
import Song, { ISong } from '../../components/Song';
import { IHeaderActionsProps } from '../../components/Header/HeaderActions';
import viewPlaylistHeaders from './headers';
import AudioPreview from '../../components/AudioPreview';
import Loading from '../../components/Loading';

interface IMatchParams {
  id: string;
}

interface IPlaylistDetailsProps extends RouteComponentProps<IMatchParams> {
  loading: boolean;
  songs: ISong[];
  requestPlaylistDetails: (id: string) => void;
  goBack: () => void;
  pauseSong: () => void;
}

interface IPlaylistDetailsState {
  search: string;
  songs: ISong[];
}

class PlaylistDetails extends Component<IPlaylistDetailsProps, IPlaylistDetailsState> {
  constructor(props: IPlaylistDetailsProps) {
    super(props);
    this.state = {
      search: '',
      songs: this.props.songs,
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {
    this.props.requestPlaylistDetails(this.props.match.params.id);
  }

  componentDidUpdate(prevProps: IPlaylistDetailsProps) {
    if (prevProps.songs.length !== this.props.songs.length) {
      this.setState({
        songs: this.props.songs,
      });
    }
  }

  componentWillUnmount() {
    this.props.pauseSong();
  }

  handleSearch(e: React.FormEvent<HTMLInputElement>): void {
    this.setState({
      search: e.currentTarget.value,
    });
  }

  handleSelect(id: number): void {
    const songs = this.props.songs.map((song: ISong) => {
      if (song.id === id) {
        song.selected = !song.selected;
      }
      return song;
    });
    this.setState({ songs });
  }

  handleBack(): void {
    this.props.goBack();
  }

  render() {
    const actions: IHeaderActionsProps = {
      back: this.handleBack,
    };

    const songs = this.state.songs
      .filter(
        (song: ISong) =>
          song.name.toLowerCase().includes(this.state.search.toLowerCase()) ||
          song.artist.toLowerCase().includes(this.state.search.toLowerCase()) ||
          song.genre.name
            .toLowerCase()
            .includes(this.state.search.toLowerCase())
      )
      .map((song: ISong, index: number) => {
        return (
          <Song
            key={index}
            id={song.id}
            name={song.name}
            path={song.path}
            artist={song.artist}
            genre={song.genre}
            duration={song.duration}
          />
        );
      });

    const content = (this.props.songs.length > 0)
      ? <SimpleBar style={{ height: '100%' }}>{songs}</SimpleBar>
      : <Loading />;
    return (
      <React.Fragment>
        <AudioPreview />
        <Header
          search={{ value: this.state.search, handleSearch: this.handleSearch }}
          actions={actions}
        />
        <section>
          <GridHeader columns={viewPlaylistHeaders} />
          {content}
        </section>
      </React.Fragment>
    );
  }
}

export default withRouter(PlaylistDetails);
