import {
  IPlaylistServer,
  IGenreServer,
  ISongServer,
  IUserServer,
} from '../actions';
import { GENERIC_PLAYLIST_VALUE } from '../constants/users';

export const convertServerPlaylist = (playlist: IPlaylistServer) => {
  return {
    id: playlist.playlist_id,
    name: playlist.playlist_name,
    createdAt: playlist.created_at,
    client: {
      id: playlist.user_id,
      name: (playlist.family === GENERIC_PLAYLIST_VALUE) ? `${playlist.first_name} ${playlist.last_name}` : 'Todos',
    },
    duration: playlist.duration,
    family: playlist.family,
  };
};

export const convertServerPlaylists = (playlists: IPlaylistServer[]) => {
  return (playlists && playlists.length > 0)
    ? playlists.map((playlist) => convertServerPlaylist(playlist))
    : [];
};

export const convertServerGenres = (genres: IGenreServer[]) => {
  return genres.map((genre) => (
    {
      id: genre.id,
      name: genre.name,
      songsCount: genre.songs_count,
    }
  ));
};

export const convertServerSongs = (songs: ISongServer[]) => {
  return songs.map((song) => (
    {
      id: song.id,
      name: song.name,
      path: song.path,
      artist: song.artist,
      genre: {
        id: song.genre_id,
        name: song.genre_name,
      },
      duration: song.duration,
    }));
};

export const convertServerUsers = (users: IUserServer[]) => {
  const newUsers = users.map(
    (user: IUserServer) => {
      return {
        id: user.id,
        name: `${user.first_name} ${user.last_name}`,
      };
    }
  );
  newUsers.unshift({
    id: 0,
    name: 'Todos los usuarios',
  });
  return newUsers;
};
