import { combineReducers } from 'redux';
import { History } from 'history';
import { connectRouter } from 'connected-react-router';

import { playlists, IPlaylist } from './playlists';
import { genres, IGenre } from './genres';
import { genreDetails, IGenreDetailsState, ISong } from './genreDetails';
import { users, IUser } from './users';
import { createPlaylist } from './createPlaylist';
import { editPlaylist, IEditPlaylistState } from './editPlaylist';
import { playlistDetails, IPlaylistDetails } from './playlistDetails';
import { uiReducer, IUiState } from './ui';
import { audioPreview, IAudioPreviewState } from './audioPreview';

const dataReducer = combineReducers({
  playlists,
  genres,
  users,
});

interface IDataState {
  playlists: IPlaylist[];
  genres: IGenre[];
  users: IUser[];
}

export interface IState {
  router: any;
  data: IDataState;
  ui: IUiState;
  createPlaylist: ISong[];
  editPlaylist: IEditPlaylistState;
  playlistDetails: IPlaylistDetails;
  genreDetails: IGenreDetailsState;
  audioPreview: IAudioPreviewState;
}

const rootReducer = (history: History) => combineReducers({
  router: connectRouter(history),
  data: dataReducer,
  ui: uiReducer,
  createPlaylist,
  editPlaylist,
  playlistDetails,
  genreDetails,
  audioPreview,
});

export default rootReducer;
