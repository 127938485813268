import { ofType } from 'redux-observable';
import { push } from 'connected-react-router';
import { mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as Routes from '../constants/routes';
import ActionTypes from '../constants/ActionTypes';

export const goToHome = (action$: any) => action$.pipe(
  ofType(ActionTypes.GO_TO_HOME),
  mergeMap(() => {
    return of(push(Routes.home()));
  }),
);

export const goToGenres = (action$: any) => action$.pipe(
  ofType(ActionTypes.GO_TO_GENRES),
  mergeMap(() => {
    return of(push(Routes.genres()));
  }),
);

export const goToNewPlaylist = (action$: any) => action$.pipe(
  ofType(ActionTypes.GO_TO_NEW_PLAYLIST),
  mergeMap(() => {
    return of(push(Routes.createPlaylist()));
  }),
);

export const goToGenreDetails = (action$: any) => action$.pipe(
  ofType(ActionTypes.GO_TO_GENRE_DETAILS),
  mergeMap(({ payload }) => {
    return of(push(Routes.genreDetails(payload)));
  }),
);

export const goToPlaylistEdit = (action$: any) => action$.pipe(
  ofType(ActionTypes.GO_TO_PLAYLIST_EDIT),
  mergeMap(({ payload }) => {
    return of(push(Routes.playlistEdit(payload)));
  }),
);

export const goToPlaylistDetails = (action$: any) => action$.pipe(
  ofType(ActionTypes.GO_TO_PLAYLIST_DETAILS),
  mergeMap(({ payload }) => {
    return of(push(Routes.playlistDetails(payload)));
  }),
);
