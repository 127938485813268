// tslint:disable:no-empty-interface
import React, { Component } from 'react';
import { withRouter } from 'react-router';

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Header from '../../components/Header';
import GridHeader from '../../components/GridHeader';
import Song, { ISong } from '../../components/Song';
import { IHeaderActionsProps } from '../../components/Header/HeaderActions';
import Modal, { IModalProps } from '../../components/Modal';
import newPlaylistheaders from './headers';
import { IUser } from '../../reducers/users';
import {
  IDraggableProvided,
  IDroppableProvided,
  IDragResult,
  INewPlaylistProps,
  INewPlaylistState
} from './interfaces';

import './NewPlaylist.scss';
import { numberToTime } from '../../utils/utils';
import AudioPreview from '../../components/AudioPreview';

class NewPlaylist extends Component<INewPlaylistProps, INewPlaylistState> {
  constructor(props: INewPlaylistProps) {
    super(props);

    this.state = {
      search: '',
      songs: this.props.songs,
      cancelModal: false,
      saveModal: false,
      currentSaveModalStep: 0,
    };

    this.onDragEnd = this.onDragEnd.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    this.props.requestUsers();
  }

  componentDidUpdate(prevProps: INewPlaylistProps) {
    if (prevProps.users.length !== this.props.users.length) {
      this.props.setPlaylistUser(this.props.users[0].id);
    }

    if (prevProps.songs.length !== this.props.songs.length) {
      this.setState({
        songs: this.props.songs,
      });
    }
  }

  componentWillUnmount() {
    this.props.pauseSong();
  }

  /*Modal methods*/
  modalCancelContent(): IModalProps {
    return {
      close: this.closeModal.bind(this),
      title: 'Cambios sin guardar',
      buttons: [
        {
          bgColor: 'red',
          text: 'Cerrar sin guardar',
          click: this.handleGoBack.bind(this),
        },
        {
          bgColor: 'green',
          text: 'Seguir editando',
          click: this.closeModal.bind(this),
        }
      ],
    };
  }

  modalSaveContent(): IModalProps[] {
    const userOptions: JSX.Element[] = this.props.users.map(
      (user: IUser, i: number) => (
        <option key={i} value={user.id}>
          {user.name}
        </option>
      )
    );

    return [
      {
        close: this.closeModal.bind(this),
        title: 'Nombre de la lista',
        content: () => (
          <input
            value={this.props.name}
            // tslint:disable-next-line:jsx-no-bind
            onChange={this.handleNameChange.bind(this)}
          />
        ),
        buttons: [
          {
            bgColor: 'green',
            text: 'Siguiente',
            disabled: this.props.name !== '' ? false : true,
            click: () =>
              this.setState({
                currentSaveModalStep: 1,
              }),
          }
        ],
      },
      {
        close: this.closeModal.bind(this),
        title: 'Elegir usuario',
        content: () => (
          <select
            id='user'
            // tslint:disable-next-line:jsx-no-bind
            onChange={this.handleUserChange.bind(this)}
            value={this.props.user_id}
          >
            {userOptions}
          </select>
        ),
        buttons: [
          {
            bgColor: 'green',
            text: 'Guardar',
            disabled: (this.props.name !== '' && !this.props.savingPlaylist) ? false : true,
            click: this.handleSaveNewPlaylist.bind(this),
          }
        ],
      }
    ];
  }

  handleGoBack(): void {
    this.props.resetPlaylist();
    this.props.goToHome();
  }

  handleNameChange(e: React.FormEvent<HTMLInputElement>): void {
    this.props.setPlaylistName(e.currentTarget.value);
  }

  handleUserChange(e: React.FormEvent<HTMLSelectElement>): void {
    this.props.setPlaylistUser(+e.currentTarget.value);
  }

  closeModal(): void {
    this.setState({
      cancelModal: false,
      saveModal: false,
      currentSaveModalStep: 0,
    });
  }

  handleCancelNewPlaylist(): void {
    this.setState({
      cancelModal: true,
    });
  }

  handleSaveModalNewPlaylist(): void {
    this.setState({
      saveModal: true,
    });
  }

  handleSaveNewPlaylist(): void {
    const songs: number[] = this.props.songs.map(
      (song: ISong): number => song.id
    );
    const playlist = {
      user_id: this.props.user_id,
      name: this.props.name,
      songs,
    };

    this.props.requestCreatePlaylist(playlist);
  }
  /*End Modal Methods*/

  handleSearch(e: React.FormEvent<HTMLInputElement>): void {
    this.setState({
      search: e.currentTarget.value,
    });
  }

  handleGoToGenres(): void {
    this.props.goToGenres();
  }

  handleDelete(id: number): void {
    this.props.deleteSongFromPlaylist(id);
  }

  onDragEnd(result: IDragResult) {
    if (!result.destination) {
      return;
    }

    const sortSong = {
      songs: this.props.songs,
      oldIndex: result.source.index,
      newIndex: result.destination.index,
    };

    this.props.sortPlaylistSongs(sortSong);
  }

  getSongTemplate(song: ISong, index: number): JSX.Element {
    song.deleteFn = () => this.handleDelete(song.id);
    return (
      <Song
        key={index}
        id={song.id}
        name={song.name}
        path={song.path}
        artist={song.artist}
        genre={song.genre}
        duration={song.duration}
        deleteFn={song.deleteFn}
      />
    );
  }

  getDropContent(provided: IDroppableProvided) {
    return (
      <div
        ref={provided.innerRef}
        className='songs-grid'
      >
        {this.props.songs.map((song: ISong, index: number) => this.getMappedSong(song, index))}
        {provided.placeholder}
      </div>
    );
  }

  getMappedSong(song: ISong,  index: number): JSX.Element {
    return (
      <Draggable key={index} draggableId={index + 1} index={index}>
        {(providedElement: IDraggableProvided) => this.getDragContent(song, index, providedElement)}
      </Draggable>
    );
  }

  getDragContent(song: ISong, index: number, providedElement: IDraggableProvided): JSX.Element {
    return (
      <div
        ref={providedElement.innerRef}
        {...providedElement.draggableProps}
        {...providedElement.dragHandleProps}
        className='row-container'
      >
        {this.getSongTemplate(song, index)}
      </div>
    );
  }

  getPlaylistLength(): JSX.Element | null {
    if (this.props.songs.length > 0) {
      const length = numberToTime(this.props.songs.reduce((acc: number, song: ISong) => acc + song.duration, 0));
      return <p className='playlistLength'>Duración total: {length}</p>;
    } else {
      return null;
    }
  }

  render() {
    const actions: IHeaderActionsProps = {
      goToGenres: this.handleGoToGenres.bind(this),
      save: {
        disabled: this.props.songs.length > 0 ? false : true,
        submit: this.handleSaveModalNewPlaylist.bind(this),
      },
      cancel:
        this.props.songs.length > 0
          ? this.handleCancelNewPlaylist.bind(this)
          : this.handleGoBack.bind(this),
    };

    const modal = this.state.cancelModal ? (
      <Modal {...this.modalCancelContent()} />
    ) : this.state.saveModal ? (
      <Modal {...this.modalSaveContent()[this.state.currentSaveModalStep]} />
    ) : null;

    return (
      <React.Fragment>
        {modal}
        <AudioPreview />
        <Header
          search={{ value: this.state.search, handleSearch: this.handleSearch }}
          actions={actions}
        />
        <section>
          {this.getPlaylistLength()}
          <GridHeader columns={newPlaylistheaders} />
          <SimpleBar>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId='droppable'>
                {(provided: IDroppableProvided) => this.getDropContent(provided)}
              </Droppable>
            </DragDropContext>
          </SimpleBar>
        </section>
      </React.Fragment>
    );
  }
}

export default withRouter(NewPlaylist);
