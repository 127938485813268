import React from 'react';

import Row, { ICellProps } from '../Row';

import './Playlist.scss';
import { numberToTime, formatDate } from '../../utils/utils';

export interface IClient {
  id: number;
  name: string;
}

export interface IPlaylist {
  id: number;
  name: string;
  createdAt: string;
  duration: number;
  family: number | null;
  client: IClient;
  editFn: () => void;
  detailsFn: () => void;
  duplicateFn: () => void;
  deleteFn: () => void;
}

const Playlist = (props: IPlaylist) => {
  const { name, createdAt, duration, client, family, editFn, detailsFn, duplicateFn, deleteFn } = props;

  const content: ICellProps[] = [
    {
      type: 'composedText',
      content: (
        <div>
          <p className='first-line'>{name}</p>
          <p className='second-line'>
            Creada: <span>{formatDate(createdAt)}</span>
          </p>
        </div>
      ),
    },
    {
      type: 'text',
      content: numberToTime(duration).toString(),
    },
    {
      type: 'text',
      content: client.name,
    },
    {
      type: 'action',
      content: <span className='col-edit' onClick={editFn} />,
    },
    {
      type: 'action',
      content: <span className='col-details' onClick={detailsFn} />,
    },
    {
      type: 'action',
      content: (family === null) ? <span className='col-duplicate' onClick={duplicateFn} /> : <span />,
    },
    {
      type: 'action',
      content: <span className='col-delete' onClick={deleteFn} />,
    }
  ];
  return <Row columns={content} classes='playlist' />;
};

export default Playlist;
