import { Action } from 'typescript-fsa';

import ActionTypes from '../constants/ActionTypes';
import { convertServerGenres } from '../utils/converters';

export interface IGenre {
  id: number;
  name: string;
  songsCount: number;
}

export interface IGenresState {
  loading: boolean;
  genres: IGenre[];
}

const initialState: IGenresState = {
  loading: false,
  genres: [],
};

export const genres = (state = initialState, action: Action<any>) => {
  switch (action.type) {
    case ActionTypes.REQUEST_GENRES: {
      return {
        loading: true,
        genres: [],
      };
    }
    case ActionTypes.SUCCESS_GENRES: {
      return {
        loading: false,
        genres: convertServerGenres(action.payload),
      };
    }
    case ActionTypes.FAILURE_GENRES: {
      return initialState;
    }
    default:
      return state;
  }
};
